<template>
  <div class="elevation-6 pl-6 pr-6 pa-2 pb-6">

    <v-overlay :value="loading || savingRequest">
      <v-progress-circular
        color="primary"
        indeterminate
        size="90"
        width="6"
        :disabled="savingRequest"
      ></v-progress-circular>
    </v-overlay>

    <v-subheader class="pl-0">
      <v-row>
        <v-col class="pl-2">
          <p>
            Home <v-icon color="black">mdi-chevron-right</v-icon>
            Lista de solicitações <v-icon color="black">mdi-chevron-right</v-icon>
            <b>Adicionar solicitação</b>
          </p>
        </v-col>
        <v-col class="pr-0" align="right" v-if="this.step > 1">
          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon color="black">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <RequestCancel></RequestCancel>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-subheader>
    <v-row justify="center" v-if="!stepOne">
      <v-btn
        @click="backStep"
        class="mt-4"
        outlined
        :disabled="stepTwo"
        rounded
      >
        <v-icon color="black">mdi-chevron-left</v-icon> Voltar</v-btn
      >
      <v-stepper
        alt-labels
        elevation="0"
        v-model="step"
        width="800"
        light
        justify="center"
      >
        <v-stepper-header>
          <v-stepper-step step="1"> Serviço </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="2" @click="setStep(2)" class="clicable"> Imóvel </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3" @click="setStep(3)" class="clicable"> Observações </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="4" @click="setStep(4)" class="clicable"> Partes </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <v-btn
        @click="nextStep"
        class="mt-4"
        color="primary"
        v-if=!stepFour
        rounded
      >
        Avançar
        <v-icon color="white">mdi-chevron-right</v-icon>
      </v-btn>
      <RequestComplete v-else></RequestComplete>
    </v-row>
    <RequestInfosBar
      v-if="!stepOne"
    ></RequestInfosBar>
    <NewRequestService
      v-show="stepOne"
      @nextStep="nextStep"
    ></NewRequestService>
    <NewRequestRealty v-show="stepTwo"></NewRequestRealty>
    <NewRequestObservation v-show="stepThree"></NewRequestObservation>
    <NewRequestTenant v-show="stepFour"></NewRequestTenant>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NewRequestService from '../../components/NewRequest/Service'
import NewRequestRealty from '../../components/NewRequest/Realty'
import NewRequestTenant from '../../components/NewRequest/Tenant'
import NewRequestObservation from '../../components/NewRequest/Observation'
import RequestInfosBar from '../../components/Request/InfosBar'
import RequestCancel from '../../components/Request/Cancel'
import RequestComplete from '../../components/Request/Complete'

export default {
  components: {
    NewRequestService,
    NewRequestRealty,
    NewRequestTenant,
    RequestInfosBar,
    NewRequestObservation,
    RequestCancel,
    RequestComplete
  },
  data () {
    return {
      step: 1,
      savingRequest: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['requestServiceType']),
    stepOne: function () {
      return this.step == 1
    },
    stepTwo: function () {
      return this.step == 2
    },
    stepThree: function () {
      return this.step == 3
    },
    stepFour: function () {
      return this.step == 4
    }
  },
  created () {
    if (this.$route.params.id) {
      this.loading = true
      const payload = {
        id: this.$route.params.id,
        revision: null
      }
      this.getRequest(payload).then(() => {
        this.step = 2
      })
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  methods: {
    ...mapActions(['saveRequest', 'getRequest']),
    async nextStep () {
      if (this.step < 4 && this.step > 1) {
        return await this.saveData().then(() => {
         this.step++
        })
      }
      this.step++  
    },
    backStep () {
      if (this.step > 1) {
        this.step--
      }
    },
    async saveData () {
      this.savingRequest = true
      await this.saveRequest()
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.savingRequest = false
        })
    },
    async setStep (newStep) {
      await this.saveData().then(() => {
        this.step = newStep
      })
    }
  }
}
</script>

<style>
</style>
