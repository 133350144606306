<template>
  <v-card elevation="0" class="pa-0">
    <h5 class="primary--text bold ma-0 pa-0">Partes</h5>
    <h6 class="text_dark_grey">
      Adicione as pessoas que farão parte da locação.
    </h6>
    <TenantTable></TenantTable>
  </v-card>
</template>

<script>
import TenantTable from "../Tenant/Table";

export default {
  name: "NewRequestTenant",
  components: {
    TenantTable,
  }
};
</script>

<style>
</style>
