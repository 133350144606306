<template>
  <div>
    <v-row>
      <h4 class="bold primary--text">Escolha o serviço</h4>
    </v-row>
    <v-row>
      <p>Favor escolher o serviço desejado.</p>
    </v-row>
    <v-row>
      <v-img
        :src="require('../../assets/images/Fianca_Mais.png')"
        class="clicable"
        contain
        height="200"
        @click="selectService(1)"
        position="left"
      />
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: "NewRequestService",
  methods: {
    ...mapMutations([
      'setServiceType',
    ]),
    selectService(service) {
      this.setServiceType(service)
      this.$emit('nextStep')
    }
  }
};
</script>
